import { Box, Button, Card, CardContent, IconButton, TextField, Typography, Alert, Snackbar } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import GridLayout, { Layout } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useParams, useNavigate } from "react-router-dom";
import PageTileAndBreadCrumb from "../../../components/PageTileAndBreadCrumb";
import { CancelRounded, DeleteRounded, SaveRounded, ListRounded } from "@mui/icons-material";
import { AddRounded } from "@mui/icons-material";
import AddWidgetModal from "./AddWidgetModal";
import { AgGridReact } from "ag-grid-react";
import { WidthProvider } from "react-grid-layout";
import { useAppContext } from "../../../App.context";

const ReactGridLayout = WidthProvider(GridLayout);

const ViewCustomDashboard: React.FC = () => {
    const { id } = useParams();
    const rowHeight = 60;
    const gap = 10; // Define the gap between grid cells

    const navigate = useNavigate();
    const [layout, setLayout] = useState<any[]>([]);
    const [userView, setUserView] = useState<string>("view");
    const [dashboard, setDashboard] = useState<any>({
        name: "",
        layout: [],
    });
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openAddWidgetModal, setOpenAddWidgetModal] = useState(false);
    const [userMessage, setUserMessage] = useState<{ message: string; alertSeverity: any }>({
        message: "",
        alertSeverity: "",
    });
    const { params, setParams } = useAppContext();

    useEffect(() => {
        const savedDashboards = localStorage.getItem("customDashboards");
        const dashboard = JSON.parse(savedDashboards || "{}").find((dashboard: any) => dashboard.id === id);
        setDashboard(dashboard);
        setLayout(dashboard.layout);
        console.log("dashboard", dashboard);
    }, [id]);

    const handleAddItem = () => {
        setOpenAddWidgetModal(true);
    };

    const handleCloseAddWidgetModal = () => {
        setOpenAddWidgetModal(false);
    };

    const addWidget = (widget: any) => {
        const newItem: any = {
            i: `item-${layout.length + 1}`,
            ...widget.metadata,
            ...widget.data,
            ...widget.layout,
        };
        setLayout([...layout, newItem]);
        setOpenAddWidgetModal(false);
    };

    const removeItem = (id: string) => {
        setLayout(layout.filter((item) => item.i !== id));
    };

    const onLayoutChange = (newLayout: Layout[]) => {
        setLayout(
            newLayout.map((layoutItem) => ({
                ...layoutItem,
                ...layout.find((item) => item.i === layoutItem.i), // Retain custom properties like bgColor and type
            }))
        );
    };

    const handleSave = () => {
        const user = "current_user"; // Replace with actual user context
        const timestamp = new Date().toISOString();
        const newDashboard = {
            id: `dashboard-${timestamp}`, // Unique identifier
            name: dashboard.name,
            user,
            layout,
            timestamp,
        };

        // Get existing dashboards from localStorage
        const savedDashboards = JSON.parse(localStorage.getItem("customDashboards") || "[]");
        const updatedDashboards = [...savedDashboards, newDashboard];

        // Save updated dashboards to localStorage
        localStorage.setItem("customDashboards", JSON.stringify(updatedDashboards));

        setUserMessage({
            message: "Dashboard saved successfully",
            alertSeverity: "success",
        });
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb selectedDashboard={dashboard} title={"View Dashboard"} showDashboardList={true} />

            {userView === "view" && (
                <>
                    <Grid size={12}>
                        <Card>
                            <CardContent sx={{ paddingBottom: "0px !important" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "16px",
                                    }}
                                >
                                    <div className="title flex flex-row">
                                        <Typography variant="body2" component="div" sx={{ marginRight: "16px" }}>
                                            Name: <strong>{dashboard.name}</strong>
                                        </Typography>
                                        <Typography variant="body2" component="div" sx={{ marginRight: "16px" }}>
                                            Description:{" "}
                                            <strong>
                                                {dashboard.description ? dashboard.description : "No description"}
                                            </strong>
                                        </Typography>
                                        <Typography variant="body2" component="div" sx={{ marginRight: "16px" }}>
                                            Created At:{" "}
                                            <strong>
                                                {dashboard.createdAt ? dashboard.createdAt : "No created at"}
                                            </strong>
                                        </Typography>
                                        <Typography variant="body2" component="div" sx={{ marginRight: "16px" }}>
                                            Updated At:{" "}
                                            <strong>
                                                {dashboard.updatedAt ? dashboard.updatedAt : "No updated at"}
                                            </strong>
                                        </Typography>
                                        <Typography variant="body2" component="div">
                                            Created by: <strong>{dashboard.user}</strong>
                                        </Typography>
                                    </div>
                                    <div className="flex flex-row">
                                        <Button
                                            startIcon={<ListRounded />}
                                            sx={{ marginRight: "8px" }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setParams({
                                                    settingsSelectedTabNumber: 4,
                                                });
                                                navigate("/admin/settings");
                                            }}
                                        >
                                            Go to Dashboards
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={12}>
                        <div className="relative" style={{ minHeight: rowHeight * 10 + gap * 9, width: "100%" }}>
                            <ReactGridLayout
                                className="layout relative z-10"
                                layout={dashboard.layout}
                                verticalCompact={true}
                                onLayoutChange={onLayoutChange}
                                rowHeight={rowHeight}
                                width={1200}
                                cols={12}
                                margin={[10, 10]}
                                containerPadding={[0, 0]}
                                isResizable={false}
                                isDraggable={false}
                            >
                                {dashboard.layout.map((item: any) => (
                                    <div
                                        key={item.i}
                                        style={{
                                            backgroundColor: item.bgColor || "#fff",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "8px",
                                            border: "1px solid #ccc",
                                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                            position: "relative",
                                        }}
                                    >
                                        <div className="w-full h-full flex flex-col items-center justify-center p-2">
                                            <div className="w-full flex mb-2">
                                                <Typography variant="h6">{item.title}</Typography>
                                            </div>

                                            {item.type === "table" && (
                                                <div
                                                    style={{ height: "100%", width: "100%" }}
                                                    className={"ag-theme-quartz"}
                                                >
                                                    <AgGridReact
                                                        rowData={item.rows}
                                                        columnDefs={item.columns}
                                                        defaultColDef={{
                                                            flex: 1,
                                                            minWidth: 100,
                                                            resizable: true,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </ReactGridLayout>
                        </div>
                    </Grid>
                </>
            )}

            {userView !== "view" && (
                <Grid size={12}>
                    <Card>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                                <TextField
                                    label="Dashboard Name"
                                    variant="outlined"
                                    value={dashboard.name}
                                    sx={{ width: "250px" }}
                                />
                                <Box display="flex" gap={2}>
                                    <Button
                                        startIcon={<AddRounded />}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate("/admin/custom-dashboard/create")}
                                    >
                                        Add Widget
                                    </Button>
                                    <Button
                                        startIcon={<CancelRounded />}
                                        onClick={() => navigate("/admin/settings")}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        startIcon={<SaveRounded />}
                                        variant="contained"
                                        color="success"
                                        disabled={dashboard.name === ""}
                                        onClick={handleSave}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>

                            <AddWidgetModal
                                openModal={openAddWidgetModal}
                                handleCloseModal={handleCloseAddWidgetModal}
                                addWidget={addWidget}
                            />
                            <div className="relative" style={{ minHeight: rowHeight * 10 + gap * 9, width: "100%" }}>
                                <div
                                    className="absolute inset-0 grid grid-cols-12 gap-x-[10px] gap-y-[10px]"
                                    style={{
                                        gridTemplateRows: `repeat(10, ${rowHeight}px)`,
                                        height: "100%",
                                        zIndex: 0,
                                    }}
                                >
                                    {Array.from({ length: 120 }).map((_, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                backgroundColor: "#f0f0f0",
                                                border: "1px solid #e0e0e0",
                                            }}
                                        ></div>
                                    ))}
                                </div>

                                <ReactGridLayout
                                    className="layout relative z-10"
                                    layout={dashboard.layout}
                                    onLayoutChange={onLayoutChange}
                                    rowHeight={rowHeight}
                                    width={1200}
                                    cols={12}
                                    margin={[10, 10]}
                                    containerPadding={[0, 0]}
                                    isResizable={true}
                                    isDraggable={true}
                                >
                                    {dashboard.layout.map((item: any) => (
                                        <div
                                            key={item.i}
                                            style={{
                                                backgroundColor: item.bgColor || "#fff",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "8px",
                                                border: "1px solid #ccc",
                                                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                                position: "relative",
                                            }}
                                        >
                                            <IconButton
                                                color="error"
                                                size="small"
                                                style={{ position: "absolute", top: 5, right: 5, zIndex: 1000 }}
                                                onClick={() => removeItem(item.i)}
                                            >
                                                <DeleteRounded />
                                            </IconButton>

                                            <div className="w-full h-full flex flex-col items-center justify-center p-2">
                                                <div className="w-full flex mb-2">
                                                    <Typography variant="h6">{item.title}</Typography>
                                                </div>

                                                {item.type === "table" && (
                                                    <div
                                                        style={{ height: "100%", width: "100%" }}
                                                        className={"ag-theme-quartz"}
                                                    >
                                                        <AgGridReact
                                                            rowData={item.rows}
                                                            columnDefs={item.columns}
                                                            defaultColDef={{
                                                                flex: 1,
                                                                minWidth: 100,
                                                                resizable: true,
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </ReactGridLayout>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            )}
            <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={handleCloseSnackBar}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default ViewCustomDashboard;
