import {
    Button,
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tabs,
    Tab,
    TextField,
    Card,
    CardContent,
    Typography,
    Dialog,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid2";
import widgetsData from "../../../data/mock/widgets.json";
import { BarChart } from "@mui/icons-material";
import { CreditCard, TableBar } from "@mui/icons-material";

const AddWidgetModal: React.FC<{
    openModal: boolean;
    handleCloseModal: () => void;
    addWidget: (widget: any) => void;
}> = ({ openModal, handleCloseModal, addWidget }) => {
    const [widgetName, setWidgetName] = useState("");
    const [widgetType, setWidgetType] = useState("");
    const [dataSource, setDataSource] = useState("");
    const [bgColor, setBgColor] = useState("#ffffff");
    const [width, setWidth] = useState(3);
    const [height, setHeight] = useState(2);
    const [tabValue, setTabValue] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("All");

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const widgets: any[] = widgetsData;
    const categories = widgets.map((widget) => widget.metadata.type);
    const uniqueCategories = [...new Set(categories)];
    uniqueCategories.unshift("All");

    const filteredWidgets = widgets.filter(
        (widget) =>
            (selectedCategory === "All" || widget?.metadata?.type === selectedCategory) &&
            widget?.metadata?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleAddFromLibrary = (widget: any) => {
        const newWidget = {
            metadata: widget.metadata,
            layout: widget.layout,
            data: widget.data,
        };
        addWidget(newWidget);
        handleCloseModal();
    };

    const getIconForWidget = (type: string) => {
        switch (type) {
            case "chart":
                return <BarChart fontSize="medium" />;
            case "table":
                return <TableBar fontSize="medium" />;
            case "card":
                return <CreditCard fontSize="medium" />;
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: {
                    maxWidth: "70vw",
                    maxHeight: "70vh",
                    borderRadius: "12px",
                },
            }}
        >
            <DialogTitle sx={{ textAlign: "left" }}>
                <Typography variant="h6" fontWeight="bold">
                    Add New Widget
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ minHeight: "60vh" }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{ marginBottom: 3, borderBottom: 1, borderColor: "divider", textAlign: "left" }}
                >
                    <Tab
                        label="Create Your Own"
                        sx={{ textTransform: "none", fontWeight: "bold", textAlign: "left" }}
                    />
                    <Tab
                        label="Choose from Library"
                        sx={{ textTransform: "none", fontWeight: "bold", textAlign: "left" }}
                    />
                </Tabs>

                {tabValue === 0 && (
                    <Grid container spacing={4}>
                        <Grid size={6}>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    label="Widget Name"
                                    variant="outlined"
                                    value={widgetName}
                                    onChange={(e) => setWidgetName(e.target.value)}
                                    sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <InputLabel>Widget Type</InputLabel>
                                <Select
                                    value={widgetType}
                                    onChange={(e) => setWidgetType(e.target.value)}
                                    sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
                                >
                                    <MenuItem value="Chart">Chart</MenuItem>
                                    <MenuItem value="Table">Table</MenuItem>
                                    <MenuItem value="Card">Card</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <InputLabel>Data Source</InputLabel>
                                <Select
                                    value={dataSource}
                                    onChange={(e) => setDataSource(e.target.value)}
                                    sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
                                >
                                    <MenuItem value="API">API</MenuItem>
                                    <MenuItem value="Database">Database</MenuItem>
                                    <MenuItem value="CSV">CSV File</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                label="Background Color"
                                type="color"
                                value={bgColor}
                                onChange={(e) => setBgColor(e.target.value)}
                                sx={{ marginBottom: 2, backgroundColor: "#f9f9f9", borderRadius: "8px" }}
                            />
                            <Grid container spacing={2}>
                                <Grid size={6}>
                                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                        <InputLabel>Width</InputLabel>
                                        <Select
                                            value={width}
                                            onChange={(e) => setWidth(Number(e.target.value))}
                                            sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
                                        >
                                            {[1, 2, 3, 4, 5, 6].map((w) => (
                                                <MenuItem key={w} value={w}>
                                                    {w}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid size={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Height</InputLabel>
                                        <Select
                                            value={height}
                                            onChange={(e) => setHeight(Number(e.target.value))}
                                            sx={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
                                        >
                                            {[1, 2, 3, 4, 5, 6].map((h) => (
                                                <MenuItem key={h} value={h}>
                                                    {h}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={6} display="flex" alignItems="start" justifyContent="center">
                            <Box
                                width="100%"
                                height="300px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                bgcolor={bgColor}
                                borderRadius="12px"
                                boxShadow={2}
                            >
                                <Typography variant="h6" color="textSecondary">
                                    {widgetName || "Widget Preview"}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )}

                {tabValue === 1 && (
                    <Grid container spacing={2}>
                        <Grid size={12}>
                            <Typography variant="h6" gutterBottom fontWeight="bold" sx={{ marginTop: 0 }}>
                                Categories
                            </Typography>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", gap: "8px", flexDirection: "row" }}>
                                    {uniqueCategories.map((category) => (
                                        <Button
                                            key={category}
                                            variant={category === selectedCategory ? "contained" : "outlined"}
                                            color={category === selectedCategory ? "primary" : "inherit"}
                                            onClick={() => setSelectedCategory(category)}
                                            sx={{
                                                textTransform: "none",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </Button>
                                    ))}
                                </div>
                                <TextField
                                    label="Search Widgets"
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    sx={{ marginTop: 2, width: "250px" }}
                                />
                            </div>
                        </Grid>
                        <Grid size={12}>
                            <Typography variant="h6" gutterBottom fontWeight="bold">
                                Available Widgets
                            </Typography>
                            <Grid container spacing={2}>
                                {filteredWidgets.map((widget) => (
                                    <Grid size={3} key={widget.metadata.name}>
                                        <Card
                                            variant="outlined"
                                            onClick={() => handleAddFromLibrary(widget)}
                                            sx={{
                                                height: "100%",
                                                borderRadius: "12px",
                                                padding: "0px",
                                                margin: "0px",
                                                "&:hover": { boxShadow: 4, cursor: "pointer" },
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {getIconForWidget(widget.metadata.type)} {widget.metadata.name}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" sx={{ margin: 0 }}>
                                                    {widget.metadata.description}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions sx={{ padding: "16px" }}>
                <Button onClick={handleCloseModal} color="secondary" variant="outlined" sx={{ padding: "8px 16px" }}>
                    Cancel
                </Button>
                <Button
                    onClick={() => addWidget({ widgetName, widgetType, dataSource, bgColor, width, height })}
                    color="primary"
                    variant="contained"
                    sx={{ padding: "8px 16px" }}
                >
                    Add Widget
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddWidgetModal;
