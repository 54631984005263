import * as Highcharts from "highcharts";

const alarmsBarChartOptions: Highcharts.Options = {
    chart: {
        type: 'column'
    },
    title: {
        text: 'Alarms'
    },
    xAxis: {
        crosshair: true,
        accessibility: {
            description: 'Countries'
        },
        categories: []
    },
    yAxis: [
        {
            min: 0,
            title: {
                text: 'Total Loss (MW)'
            }
        },
        {
            min: 0,
            opposite: true,
            title: {
                text: 'Frequency (events)'
            }
        }
    ],
    tooltip: {
        valueSuffix: ' (MW)'
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: []
};

export default alarmsBarChartOptions;