import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../utils/interceptor";

interface ProcessedAlarmsState {
    loading: boolean;
    processedAlarms: any[];
    error: string | null;
}

const initialState: ProcessedAlarmsState = {
    loading: false,
    processedAlarms: [],
    error: null,
};

export const fetchProcessedAlarms = createAsyncThunk('data/processedAlarms', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

const processedAlarmsSlice = createSlice({
    name: 'processedAlarms',
    initialState,
    reducers: {
        resetProcessedAlarmsState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProcessedAlarms.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchProcessedAlarms.fulfilled, (state, action) => {
            state.processedAlarms = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchProcessedAlarms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Failed to fetch processed alarms';
        });
    },
});

export const { resetProcessedAlarmsState } = processedAlarmsSlice.actions;
export default processedAlarmsSlice.reducer;
