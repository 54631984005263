import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, CardContent, Typography, IconButton, Tooltip, Alert, Snackbar } from "@mui/material";
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AgGridReact } from "ag-grid-react";
import {
    ClientSideRowModelModule,
    ModuleRegistry,
    ColDef,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    GridApi,
    SetFilterModule,
} from "ag-grid-enterprise";
import { AddRounded, Delete, Edit, SearchRounded, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
]);

const ListCustomDashboards: React.FC = () => {
    const navigate = useNavigate();
    const gridRef = useRef<AgGridReact>(null);
    const gridApiRef = useRef<GridApi | null>(null);
    const [dashboards, setDashboards] = useState<any[]>([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [userMessage, setUserMessage] = useState<{ message: string; alertSeverity: any }>({
        message: "",
        alertSeverity: "",
    });

    // Load dashboards from localStorage on component mount
    useEffect(() => {
        const savedDashboards = localStorage.getItem("customDashboards");
        if (savedDashboards) {
            const parsedDashboards = JSON.parse(savedDashboards);
            setDashboards(parsedDashboards);
        }
    }, []);

    const handleEdit = (id: number) => {
        console.log(`Edit dashboard with ID: ${id}`);
    };

    const handleDelete = (id: number) => {
        const savedDashboards = localStorage.getItem("customDashboards");
        if (savedDashboards) {
            const parsedDashboards = JSON.parse(savedDashboards);
            const filteredDashboards = parsedDashboards.filter((dashboard: any) => dashboard.id !== id);
            localStorage.setItem("customDashboards", JSON.stringify(filteredDashboards));
            setDashboards(filteredDashboards);

            setUserMessage({
                message: "Dashboard deleted successfully",
                alertSeverity: "success",
            });
            setOpenSnackBar(true);
        }
    };

    const handleView = (dashboard: any) => {
        navigate(`/admin/custom-dashboard/view/${dashboard.id}`);
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    const columnDefs: ColDef[] = [
        { headerName: "ID", field: "id", sortable: true, filter: true, maxWidth: 100, hide: true },
        { headerName: "Name", field: "name", sortable: true, filter: true },
        { headerName: "Description", field: "description", sortable: true, filter: true },
        { headerName: "Created By", field: "user", sortable: true, filter: true },
        { headerName: "Last Modified", field: "timestamp", sortable: true, filter: true },
        {
            headerName: "Actions",
            field: "actions",
            maxWidth: 150,
            cellRenderer: (params: any) => (
                <div style={{ display: "flex", gap: "0px", justifyContent: "flex-start", alignItems: "center" }}>
                    <Tooltip title="View" arrow>
                        <IconButton color="primary" size="medium" onClick={() => handleView(params.data)}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" arrow>
                        <IconButton color="secondary" size="medium" onClick={() => handleEdit(params.data.id)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                        <IconButton color="error" size="medium" onClick={() => handleDelete(params.data.id)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: false,
            filter: true,
            enableValue: false,
            enableRowGroup: false,
            enablePivot: false,
        };
    }, []);

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api;
        params.api.closeToolPanel();
    };

    return (
        <Grid size={12}>
            <Card>
                <CardContent>
                    <div style={{ width: "100%", height: "700px", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                            <div className="title">
                                <Typography variant="h6" component="div">
                                    Custom Dashboards
                                </Typography>
                            </div>
                            <div className="flex flex-row">
                                <Button
                                    startIcon={<AddRounded />}
                                    sx={{ marginRight: "8px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/admin/custom-dashboard/create")}
                                >
                                    Create Dashboard
                                </Button>
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="border border-gray-300 py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Search..."
                                        id="filter-text-box"
                                    />
                                    <span
                                        className="w-5 h-5 absolute left-3 transform -translate-y-1/2 text-gray-500"
                                        style={{ top: "45%" }}
                                    >
                                        <SearchRounded></SearchRounded>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "100%", width: "100%" }} className={"ag-theme-quartz"}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={dashboards}
                                pagination={true}
                                sideBar={true}
                                defaultColDef={defaultColDef}
                                ref={gridRef}
                                onGridReady={onGridReady}
                                animateRows={true}
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={handleCloseSnackBar}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default ListCustomDashboards;
