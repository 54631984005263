import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../utils/interceptor";

interface ReloadDataState {
    lambdaData: {loading: boolean, data: any, error: string | null};
    reloadData: {loading: any, data: any, error: string | null};
}

const initialState: ReloadDataState = {
    lambdaData: {loading: false, data: [], error: null},
    reloadData: {
        loading: { raw10minData: false, dailyKpisData: false, dataAvailability: false },
        data: { raw10minData: null, dailyKpisData: null, dataAvailability: null },
        error: null
    },
};

export const fetchReload10minData = createAsyncThunk('data/reloadData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

export const fetchReloadDailyKpisData = createAsyncThunk('data/reloadData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

export const fetchReloadDataAvailability = createAsyncThunk('data/reloadData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

export const fetchLambdaData = createAsyncThunk('data/lambdaData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

const reloadDataSlice = createSlice({
    name: 'reloadData',
    initialState,
    reducers: {
        resetReloadDataState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReload10minData.pending, (state) => {
                state.reloadData.loading.raw10minData = true;
                state.reloadData.error = null;
            })
            .addCase(fetchReload10minData.fulfilled, (state, action) => {
                state.reloadData.loading.raw10minData = false;
                state.reloadData.data.raw10minData = action.payload;
            })
            .addCase(fetchReload10minData.rejected, (state, action) => {
                state.reloadData.loading.raw10minData = false;
                state.reloadData.error = action.error.message || 'Failed to fetch data';
            })
            .addCase(fetchLambdaData.pending, (state) => {
                state.lambdaData.loading = true;
                state.lambdaData.error = null;
            })
            .addCase(fetchLambdaData.fulfilled, (state, action) => {
                state.lambdaData.loading = false;
                state.lambdaData.data.push(...action.payload);
            })
            .addCase(fetchLambdaData.rejected, (state, action) => {
                state.lambdaData.loading = false;
                state.lambdaData.error = action.error.message || 'Failed to fetch data';
            });
    },
});

export const { resetReloadDataState } = reloadDataSlice.actions;

export default reloadDataSlice.reducer;