import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import WindPowerIcon from "@mui/icons-material/WindPower";
import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { UserAssetData } from "../interfaces/user";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { setSelectedAssetId } from "../slices/global";
import { fetchUserSubscriptionData, resetUserSubscriptionData } from "../slices/userSlice";
import { useNavigate } from "react-router-dom";
type PageTitleAndBreadCrumbProps = {
    title: string;
    selectedDashboard?: any;
    showAssetDropDown?: boolean;
    showDashboardList?: boolean;
};

const PageTileAndBreadCrumb: React.FC<PageTitleAndBreadCrumbProps> = (props) => {
    const navigate = useNavigate();
    const [selectedAsset, setSelectedAsset] = useState<UserAssetData | null>(null);
    const { selectedAssetId } = useSelector((state: RootState) => state.global);
    const [assets, setAssets] = useState<UserAssetData[]>([]);
    const [dashboards, setDashboards] = useState<any[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<any>(null);

    const dispatch = useDispatch<AppDispatch>();
    const { userAssetsData, userBasicDataError, userAssetsLoading } = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        if (userAssetsData.length > 0) {
            setAssets(userAssetsData);
        }
    }, [userAssetsData]);

    useEffect(() => {
        if (selectedAssetId) {
            const asset: UserAssetData = userAssetsData.filter((asset) => asset.id === selectedAssetId)[0];
            setSelectedAsset(asset);
        }
    }, [selectedAssetId]);

    useEffect(() => {
        if (props.selectedDashboard) {
            setSelectedDashboard(props.selectedDashboard);
        }
    }, [props.selectedDashboard]);

    useEffect(() => {
        if (props.showDashboardList) {
            const dashboards = localStorage.getItem("customDashboards");
            if (dashboards) {
                setDashboards(JSON.parse(dashboards));
            }
        }
    }, [props.showDashboardList]);

    // Event Handlers with proper typing
    const handleAssetChange = (newAsset: any) => {
        if (newAsset) {
            setSelectedAsset(newAsset);
            dispatch(resetUserSubscriptionData());
            dispatch(fetchUserSubscriptionData("subscriptions"));
            dispatch(setSelectedAssetId(newAsset ? newAsset.id : null));
        } else {
            setSelectedAsset(null);
            dispatch(resetUserSubscriptionData());
            dispatch(setSelectedAssetId(null));
        }
    };

    const handleDashboardChange = (newDashboard: any) => {
        if (newDashboard) {
            setSelectedDashboard(newDashboard);
            navigate(`/admin/custom-dashboard/view/${newDashboard.id}`);
        } else {
            setSelectedDashboard(null);
        }
    };

    return (
        <>
            <Grid size={6}>
                <Typography component="h1" variant="h5">
                    <WindPowerIcon /> {props.title}
                </Typography>
            </Grid>
            <Grid size={6}>
                {props.showAssetDropDown && (
                    <div className={"flex justify-end items-center"}>
                        <Autocomplete
                            aria-required={true}
                            size="small"
                            sx={{
                                minWidth: 250,
                                padding: "5px",
                                maxWidth: 250,
                                paddingX: 0,
                                paddingY: 0,
                                backgroundColor: "white",
                            }}
                            value={selectedAsset}
                            onChange={(event: any, newValue: any | null) => handleAssetChange(newValue)}
                            options={assets}
                            getOptionLabel={(asset) => asset.name}
                            renderOption={(props, option, { selected }) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={key} {...optionProps}>
                                        {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Asset" placeholder="Assets" />
                            )}
                        />
                    </div>
                )}
                {props.showDashboardList && (
                    <div className={"flex justify-end items-center"}>
                        <Autocomplete
                            aria-required={true}
                            size="small"
                            sx={{
                                minWidth: 250,
                                padding: "5px",
                                maxWidth: 250,
                                paddingX: 0,
                                paddingY: 0,
                                backgroundColor: "white",
                            }}
                            value={selectedDashboard}
                            onChange={(event: any, newValue: any | null) => handleDashboardChange(newValue)}
                            options={dashboards}
                            getOptionLabel={(dashboard) => dashboard.name}
                            renderOption={(props, option, { selected }) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={key} {...optionProps}>
                                        {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Dashboard" placeholder="Dashboards" />
                            )}
                        />
                    </div>
                )}
            </Grid>
        </>
    );
};

export default PageTileAndBreadCrumb;
