import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import Grid from "@mui/material/Grid2";
import FilterFormComponent from "../../../components/Filters";
import { Card, CardContent } from "@mui/material";
import { Info } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/lab";
import { AgGridReact } from "ag-grid-react";
import { GridApi, ColDef } from "ag-grid-enterprise";
import { fetchProcessedAlarms } from "../../../slices/processedAlarmsSlice";
import { groupBy } from "lodash";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import alarmsBarChartOptions from "../../../data/chartOptions/alarms_bar_chart_options";

const WindAlarmsTab: React.FC = () => {
    const gridRef = useRef<AgGridReact>(null);
    const gridApiRef = useRef<GridApi | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const windChartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const { selectedAssetId } = useSelector((state: RootState) => state.global);
    const { filterData, filtersLoading, filtersError } = useSelector((state: RootState) => state.filterData);
    const { processedAlarms, loading, error } = useSelector((state: RootState) => state.processedAlarmsData);
    const [showInitialMessage, setShowInitialMessage] = useState<boolean>(true);
    const [tableData, setTableData] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any>(alarmsBarChartOptions);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [userMessage, setUserMessage] = useState<{ message: string | null; alertSeverity: any }>({
        message: "",
        alertSeverity: "",
    });

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: false,
            filter: true,
            enableValue: false,
            enableRowGroup: false,
            enablePivot: false,
        };
    }, []);

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api;
        params.api.closeToolPanel();
    };

    const columnDefs: ColDef[] = [
        { headerName: "Device Name", field: "device_name", initialSort: "asc" },

        { headerName: "Event ID", field: "event_id", hide: true },
        {
            headerName: "Start Time",
            type: "dateColumn",
            field: "start_time",
            valueFormatter: (params) => {
                return `${params.value?.toLocaleString()}`;
            },
        },
        {
            headerName: "End Time",
            type: "dateColumn",
            field: "end_time",
            valueFormatter: (params) => {
                return `${params.value?.toLocaleString()}`;
            },
        },
        { headerName: "Duration (mins)", type: "numericColumn", field: "duration" },
        { headerName: "Alarm Code", type: "numericColumn", field: "alarm_code" },
        {
            headerName: "Total Lost Power",
            type: "numericColumn",
            field: "total_lost_power",
            valueFormatter: (params) => {
                return `${(params.value / 1000).toFixed(2)}`;
            },
        },
        { headerName: "Status Code", field: "status_code", hide: true },
        { headerName: "ID", field: "alarm_details.id", hide: true },
        { headerName: "Asset ID", field: "alarm_details.asset_id", hide: true },
        { headerName: "Code", field: "alarm_details.code", hide: true },
        { headerName: "Description", field: "alarm_details.description" },
        { headerName: "Category", field: "alarm_details.category" },
        {
            headerName: "Custom Category",
            field: "alarm_details.custom_category",
            hide: true,
        },
        {
            headerName: "Curtailment Category",
            field: "alarm_details.curtailment_category",
            hide: true,
        },
        { headerName: "Loss Category", field: "alarm_details.loss_category", hide: true },
        { headerName: "Available", field: "alarm_details.available", hide: true },
        { headerName: "Fault Type", field: "alarm_details.fault_type", hide: true },
        { headerName: "GADS Category", field: "alarm_details.gads_category", hide: true },
        { headerName: "GADS Component", field: "alarm_details.gads_component", hide: true },
        { headerName: "IEC Category", field: "alarm_details.iec_category" },
    ];

    const calculateTotalLossAndFrequency = (alarms: any[]) => {
        // Group by "alarm_details.category"
        const groupedByCategory = groupBy(processedAlarms, "alarm_details.category");

        // Calculate total loss and frequency for each category
        const totalLossAndFrequencyByCategory = Object.keys(groupedByCategory).map((category) => {
            const alarms = groupedByCategory[category];
            const totalLoss = alarms.reduce((sum, alarm) => sum + alarm.total_lost_power, 0);
            const frequency = alarms.length;
            return { category, totalLoss, frequency };
        });

        // Group by "alarm_code"
        const groupedByCode = groupBy(processedAlarms, "alarm_code");

        // Calculate total loss and frequency for each code
        const totalLossAndFrequencyByCode = Object.keys(groupedByCode).map((code) => {
            const alarms = groupedByCode[code];
            const totalLoss = alarms.reduce((sum, alarm) => sum + alarm.total_lost_power, 0);
            const frequency = alarms.length;
            return { code, totalLoss, frequency };
        });

        return { totalLossAndFrequencyByCategory, totalLossAndFrequencyByCode };
    };

    useEffect(() => {
        dispatch(fetchProcessedAlarms("alarms?asset_id=" + selectedAssetId));
    }, [dispatch, selectedAssetId]);

    useEffect(() => {
        if (processedAlarms.length > 0) {
            setTableData(processedAlarms);
            const { totalLossAndFrequencyByCategory, totalLossAndFrequencyByCode } =
                calculateTotalLossAndFrequency(processedAlarms);
            const chartData = alarmsBarChartOptions;
            chartData.xAxis = {
                categories: totalLossAndFrequencyByCategory.map((item) => item.category),
            };
            chartData.series = [
                {
                    name: "Total Loss",
                    data: totalLossAndFrequencyByCategory.map((item) => +(item?.totalLoss / 6000).toFixed(2)),
                    type: "column",
                    tooltip: {
                        valueSuffix: " MWh",
                    },
                },
                {
                    name: "Frequency",
                    data: totalLossAndFrequencyByCategory.map((item) => item.frequency),
                    type: "column",
                    yAxis: 1,
                    tooltip: {
                        valueSuffix: " events",
                    },
                },
            ];
            setChartData(chartData);
            setShowInitialMessage(false);
        }
    }, [processedAlarms]);

    useEffect(() => {
        if (error) {
            setTableData([]);
            setUserMessage({ message: error, alertSeverity: "error" });
            setOpenSnackBar(true);
        }
    }, [error]);

    return (
        <>
            <Grid size={12}>
                <FilterFormComponent showAssets={false} showDevices={true} showSignals={false} showTimeFrame={true} />
            </Grid>

            <Grid size={12}>
                {showInitialMessage && (
                    <Card>
                        <CardContent sx={{ paddingBottom: "16px !important" }}>
                            <div className={"flex justify-center align-items-center items-center"}>
                                <div className={"flex flex-col justify-center align-items-center items-center"}>
                                    <Info fontSize={"small"}></Info>
                                    <span>Please select filters and click submit</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                )}
                {!showInitialMessage && (
                    <>
                        <Card sx={{ marginBottom: "16px" }}>
                            <CardContent>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    ref={windChartComponentRef}
                                    options={chartData}
                                ></HighchartsReact>
                            </CardContent>
                        </Card>

                        <Card>
                            <CardContent>
                                <div style={{ height: "550px", width: "100%" }} className={"ag-theme-quartz"}>
                                    <AgGridReact
                                        rowData={tableData}
                                        pagination={true}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        sideBar={true}
                                        loading={loading}
                                        ref={gridRef}
                                        onGridReady={onGridReady}
                                        animateRows={true} // Smoothly animate row updates
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </>
                )}
            </Grid>

            <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={() => setOpenSnackBar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackBar(false)}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default WindAlarmsTab;
