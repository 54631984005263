import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, CardContent, Button, Typography, Snackbar, Alert } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
    fetchReload10minData,
    fetchReloadDailyKpisData,
    fetchReloadDataAvailability,
    fetchLambdaData,
    resetReloadDataState,
} from "../../slices/reloadDataSlice";
import { AgGridReact } from "ag-grid-react";
import { ClientSideRowModelModule, ModuleRegistry, ColDef, ColumnsToolPanelModule, FiltersToolPanelModule, GridApi, SetFilterModule } from "ag-grid-enterprise";
import { RefreshRounded, SearchRounded } from "@mui/icons-material";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
]);

const columns: ColDef[] = [
    { field: "functionName", headerName: "Function Name", flex: 1 },
    { field: "timestamp", headerName: "Timestamp", flex: 1, sort: "desc" },
    { field: "duration", headerName: "Duration", flex: 1 },
    { field: "billedDuration", headerName: "Billed Duration", flex: 1 },
    { field: "memorySize", headerName: "Memory Size", flex: 1 },
    { field: "maxMemoryUsed", headerName: "Max Memory Used", flex: 1 },
    { field: "region", headerName: "Region", flex: 1 },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        cellClassRules: {
            // Conditional CSS classes for status column
            "cell-green": (params) => params.value === "SUCCESS",
            "cell-red": (params) => params.value === "FAILED",
        },
    },
];

const ReloadTab: React.FC = () => {
    const gridRef = useRef<AgGridReact>(null);
    const gridApiRef = useRef<GridApi | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { reloadData, lambdaData } = useSelector((state: RootState) => state.reloadData);
    const [tableData, setTableData] = useState([]);
    const [columnDefs, setColumnDefs] = useState(columns);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [userMessage, setUserMessage] = useState<{ message: string; alertSeverity: any }>({
        message: "",
        alertSeverity: "",
    });

    useEffect(() => {
        if (reloadData.data.raw10minData !== null) {
            console.log("10min data loaded");
        }
        if (reloadData.data.dailyKpisData !== null) {
            console.log("daily kpis data loaded");
        }
        if (reloadData.data.dataAvailability !== null) {
            console.log("data availability loaded");
        }
    }, [reloadData]);

    useEffect(() => {
        if (lambdaData.data !== null) {
            setTableData(lambdaData.data);
        }
    }, [lambdaData]);

    const fetchData = async () => {
        dispatch(fetchLambdaData("lambda-invocations?lambda_name=10min-data-ingestion-handler"));
        dispatch(fetchLambdaData("lambda-invocations?lambda_name=daily-kpis-handler"));
        dispatch(fetchLambdaData("lambda-invocations?lambda_name=data-availability-handler"));
    };

    // Auto-reload every 30 seconds
    useEffect(() => {
        fetchData(); // Fetch data initially
        const interval = setInterval(() => {
            dispatch(resetReloadDataState());
            fetchData(); // Fetch data periodically
        }, 60000 * 3); // 3 minutes

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetReloadDataState());
        };
    }, [dispatch]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: false,
            filter: true,
            enableValue: false,
            enableRowGroup: false,
            enablePivot: false,
        };
    }, []);

    const handleCloseSnakeBar = () => {
        setOpenSnackBar(false);
    };

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api;
        params.api.closeToolPanel();
    };

    return (
        <Grid size={12}>
            <Card>
                <CardContent>
                    <div style={{ width: "100%", height: "700px", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                            <div className={"title"}>
                                <Typography variant="h6" component="div">
                                    Lambda Invocations
                                </Typography>
                            </div>
                            <div className={"flex flex-row"}>
                                <Button
                                    sx={{ marginRight: "8px" }}
                                    startIcon={<RefreshRounded />}
                                    variant={"outlined"}
                                    onClick={() => {
                                        dispatch(fetchReload10minData("trigger-data-ingestion"));
                                        dispatch(fetchReloadDailyKpisData("trigger-daily-kpis"));
                                        dispatch(fetchReloadDataAvailability("trigger-data-availability"));
                                        setUserMessage({ message: "Reloaded In Progress", alertSeverity: "warning" });
                                        setOpenSnackBar(true);
                                    }}
                                >
                                    Reload All
                                </Button>
                                <Button
                                    sx={{ marginRight: "8px" }}
                                    startIcon={<RefreshRounded />}
                                    variant={"outlined"}
                                    onClick={() => {
                                        dispatch(fetchReload10minData("trigger-data-ingestion"));
                                        setUserMessage({ message: "Reloaded In Progress", alertSeverity: "warning" });
                                        setOpenSnackBar(true);
                                    }}
                                >
                                    Reload 10min Data
                                </Button>
                                <Button
                                    sx={{ marginRight: "8px" }}
                                    startIcon={<RefreshRounded />}
                                    variant={"outlined"}
                                    onClick={() => {
                                        dispatch(fetchReloadDailyKpisData("trigger-daily-kpis"));
                                        setUserMessage({ message: "Reloaded In Progress", alertSeverity: "warning" });
                                        setOpenSnackBar(true);
                                    }}
                                >
                                    Reload Daily Kpis
                                </Button>
                                <Button
                                    sx={{ marginRight: "8px" }}
                                    startIcon={<RefreshRounded />}
                                    variant={"outlined"}
                                    onClick={() => {
                                        dispatch(fetchReloadDataAvailability("trigger-data-availability"));
                                        setUserMessage({ message: "Reloaded In Progress", alertSeverity: "warning" });
                                        setOpenSnackBar(true);
                                    }}
                                >
                                    Reload Data Availability
                                </Button>
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="border border-gray-300 py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Search..."
                                        id="filter-text-box"
                                    />
                                    <span
                                        className="w-5 h-5 absolute left-3 transform -translate-y-1/2 text-gray-500"
                                        style={{ top: "45%" }}
                                    >
                                        <SearchRounded></SearchRounded>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div style={{ height: "100%", width: "100%" }} className={"ag-theme-quartz"}>
                            <AgGridReact
                                rowData={tableData}
                                pagination={true}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                sideBar={true}
                                loading={lambdaData.loading}
                                ref={gridRef}
                                onGridReady={onGridReady}
                                animateRows={true} // Smoothly animate row updates
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={handleCloseSnakeBar}
            >
                <Alert
                    onClose={handleCloseSnakeBar}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default ReloadTab;
