import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SignIn from "./pages/auth/SignIn";
import PageLayout from "./components/PageLayout";
import { AppProvider } from "./App.context";
const App: React.FC = () => {
    return (
        <AppProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<SignIn />} />
                    <Route path="*" element={<PageLayout></PageLayout>} />
                </Routes>
            </Router>
        </AppProvider>
    );
};

export default App;
