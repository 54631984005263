import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid2";
import TabComponent from "../../../components/Tabs";
import PageTileAndBreadCrumb from "../../../components/PageTileAndBreadCrumb";
import {RootState} from "../../../slices";
import ProtectedComponent from "../../../components/ProtectedComponent";
import {wind_performance_tabs} from "../../../data/tabs/wind_performance";
import {NoAssetSelected} from "../../../components/NoAssetSelected";
import WindAlarmsTab from "./WindAlarmsTab";
import WindPCTab from "./WindPCTab";
import {resetFilters} from "../../../slices/filterSlice";
import {AppDispatch} from "../../../store";
import WindLossAnalysisTab from "./WindLossAnalysisTab";
import StayTuned from "../../../components/StayTuned";
import PerformanceSettings from "./Configurations/PerformanceSettings";

const WindPerformance: React.FC = () => {
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const [selectedTab, setSelectedTab] = useState<number>(2)
    const dispatch = useDispatch<AppDispatch>()
    const handleTabChange = (newValue: number) => {
        dispatch(resetFilters())
        setSelectedTab(newValue); // Update the state with the selected tab
    };

    const required_module_id = 'wind_performance'

    const whenAssetSelected = (
        <ProtectedComponent requiredPermission={required_module_id}>
            <TabComponent selectedTabNumber={selectedTab} onTabChange={handleTabChange} tabList={wind_performance_tabs}/>
            {selectedTab === 0 && <WindPCTab></WindPCTab>}
            {selectedTab === 1 && <WindLossAnalysisTab></WindLossAnalysisTab>}
            {selectedTab === 2 && <WindAlarmsTab></WindAlarmsTab>}
            {selectedTab === 3 && <StayTuned />}
            {selectedTab === 4 && <StayTuned />}
            {selectedTab === 5 && <PerformanceSettings />}
        </ProtectedComponent>
    )

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb showAssetDropDown={true} title={'Performance Analysis'}></PageTileAndBreadCrumb>
            {selectedAssetId && whenAssetSelected}
            {(selectedAssetId === undefined || selectedAssetId === null) && <NoAssetSelected />}
        </Grid>
    )
}

export default WindPerformance;