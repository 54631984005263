import React, { createContext, useState, useContext } from "react";

// Create context
const AppContext = createContext<any>(null);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [params, setParams] = useState<any>(null); // Store your data here

    return <AppContext.Provider value={{ params, setParams }}>{children}</AppContext.Provider>;
};

// Custom hook to access context
export const useAppContext = () => useContext(AppContext);
