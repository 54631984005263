import {
    Domain,
    ManageAccounts,
    Cached,
    Subscriptions,
    Dashboard,
} from "@mui/icons-material";

export const tabList = [
    { icon: ManageAccounts, label: "Users", id: "manageAccounts" },
    { icon: Domain, label: "Assets", id: "domains" },
    { icon: Subscriptions, label: "Subscriptions", id: "subscriptions" },
    { icon: Cached, label: "Reload Data", id: "reloadData" },
    { icon: Dashboard, label: "Custom Dashboard", id: "customDashboard" },
];
