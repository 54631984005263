import React, { useState } from "react";
import GridLayout, { Layout } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Card, CardContent, Button, Box, TextField, Snackbar, Alert, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { WidthProvider } from "react-grid-layout";
import PageTileAndBreadCrumb from "../../../components/PageTileAndBreadCrumb";
import { useNavigate } from "react-router-dom";
import { AddRounded, CancelRounded, DeleteRounded, SaveRounded } from "@mui/icons-material";
import AddWidgetModal from "./AddWidgetModal";
import { AgGridReact } from "ag-grid-react";
import { useAppContext } from "../../../App.context";
const ReactGridLayout = WidthProvider(GridLayout);

const CreateCustomDashboard: React.FC = () => {
    const { params, setParams } = useAppContext();
    const rowHeight = 60;
    const gap = 10; // Define the gap between grid cells
    const [layout, setLayout] = useState<any[]>([]);
    const [dashboardName, setDashboardName] = useState<string | null>(null);
    const [dashboardDescription, setDashboardDescription] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openAddWidgetModal, setOpenAddWidgetModal] = useState(false);
    const [userMessage, setUserMessage] = useState<{ message: string; alertSeverity: any }>({
        message: "",
        alertSeverity: "",
    });
    const navigate = useNavigate();

    const handleAddItem = () => {
        setOpenAddWidgetModal(true);
    };

    const handleCloseAddWidgetModal = () => {
        setOpenAddWidgetModal(false);
    };

    const addWidget = (widget: any) => {
        setLayout((prevLayout) => {
            // Get grid width and columns from props or defaults
            const gridCols = 12; // Adjust based on your grid settings

            // Find the next available position
            let nextX = 0,
                nextY = 0;
            let occupiedPositions = new Set();

            // Populate occupied positions
            prevLayout.forEach((item) => {
                for (let x = item.x; x < item.x + (item.w || 1); x++) {
                    for (let y = item.y; y < item.y + (item.h || 1); y++) {
                        occupiedPositions.add(`${x},${y}`);
                    }
                }
            });

            // Find the first available space
            outer: for (let y = 0; ; y++) {
                for (let x = 0; x < gridCols; x++) {
                    if (!occupiedPositions.has(`${x},${y}`)) {
                        nextX = x;
                        nextY = y;
                        break outer;
                    }
                }
            }

            // Create new widget item
            const newItem: any = {
                i: `item-${prevLayout.length + 1}`,
                x: nextX,
                y: nextY,
                ...widget.metadata,
                ...widget.data,
                ...widget.layout,
            };

            return [...prevLayout, newItem];
        });

        setOpenAddWidgetModal(false);
    };

    const removeItem = (id: string) => {
        setLayout((prevLayout) => prevLayout.filter((item) => item.i !== id)); // Use functional state update
    };

    const onLayoutChange = (newLayout: any[]) => {
        // Merge the updated layout with existing custom properties,
        // while ensuring that `x` and `y` from `layoutItem` are retained.
        const updatedLayout = newLayout.map((layoutItem) => {
            const existingItem = layout.find((item) => item.i === layoutItem.i);
            return existingItem
                ? {
                      ...existingItem, // Retain custom properties from existingItem
                      ...layoutItem, // Use x, y from layoutItem, overriding existingItem
                  }
                : layoutItem; // If no existing item, use layoutItem as is
        });

        // Update layout with the merged layout
        setLayout(updatedLayout);
    };

    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const user = "current_user"; // Replace with actual user context
        const timestamp = new Date().toISOString();
        const newDashboard = {
            id: `dashboard-${timestamp}`, // Unique identifier
            name: dashboardName,
            description: dashboardDescription,
            user,
            layout,
            timestamp,
        };

        // Get existing dashboards from localStorage
        const savedDashboards = JSON.parse(localStorage.getItem("customDashboards") || "[]");
        const updatedDashboards = [...savedDashboards, newDashboard];

        // Save updated dashboards to localStorage
        localStorage.setItem("customDashboards", JSON.stringify(updatedDashboards));

        setUserMessage({
            message: "Dashboard saved successfully",
            alertSeverity: "success",
        });
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb title={"Custom Dashboard"} />

            <Grid size={12}>
                <Card>
                    <CardContent sx={{ paddingBottom: "0px !important" }}>
                        <form onSubmit={handleSave}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                                <Box display="flex" gap={2}>
                                    <TextField
                                        label="Name"
                                        variant="outlined"
                                        required
                                        value={dashboardName}
                                        onChange={(e) => setDashboardName(e.target.value)}
                                        sx={{ width: "250px" }}
                                    />
                                    <TextField
                                        label="Description"
                                        variant="outlined"
                                        required
                                        value={dashboardDescription}
                                        onChange={(e) => setDashboardDescription(e.target.value)}
                                        sx={{ width: "450px" }}
                                    />
                                </Box>
                                <Box display="flex" gap={2}>
                                    <Button
                                        startIcon={<AddRounded />}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddItem}
                                    >
                                        Add Widget
                                    </Button>
                                    <Button
                                        startIcon={<CancelRounded />}
                                        onClick={() => {
                                            setParams({
                                                settingsSelectedTabNumber: 4,
                                            });
                                            navigate("/admin/settings");
                                        }}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        startIcon={<SaveRounded />}
                                        variant="contained"
                                        color="success"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
            <Grid size={12}>
                <div className="relative" style={{ minHeight: rowHeight * 10 + gap * 9, width: "100%" }}>
                    {/* Background grid with consistent gaps */}
                    <div
                        className="absolute inset-0 grid grid-cols-12 gap-x-[10px] gap-y-[10px]"
                        style={{
                            gridTemplateRows: `repeat(10, ${rowHeight}px)`,
                            height: "100%",
                            zIndex: 0,
                        }}
                    >
                        {Array.from({ length: 120 }).map((_, index) => (
                            <div
                                key={index}
                                style={{
                                    backgroundColor: "#f0f0f0",
                                    border: "1px solid #e0e0e0",
                                }}
                            ></div>
                        ))}
                    </div>
                    <ReactGridLayout
                        className="layout relative z-10"
                        layout={layout}
                        onLayoutChange={onLayoutChange}
                        verticalCompact={false}
                        rowHeight={rowHeight}
                        width={1200}
                        cols={12}
                        margin={[10, 10]}
                        containerPadding={[0, 0]}
                        isResizable={true}
                        isDraggable={true}
                    >
                        {layout.map((item) => (
                            <div
                                key={item.i}
                                style={{
                                    backgroundColor: item.bgColor || "#fff",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "8px",
                                    border: "1px solid #ccc",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                    position: "relative",
                                }}
                            >
                                <IconButton
                                    color="error"
                                    size="small"
                                    style={{ position: "absolute", top: 5, right: 5, zIndex: 1000 }}
                                    onClick={() => removeItem(item.i)}
                                >
                                    <DeleteRounded />
                                </IconButton>

                                <div className="w-full h-full flex flex-col items-center justify-center p-2">
                                    <div className="w-full flex mb-2">
                                        <Typography variant="h6">{item.title}</Typography>
                                    </div>

                                    {item.type === "table" && (
                                        <div style={{ height: "100%", width: "100%" }} className={"ag-theme-quartz"}>
                                            <AgGridReact
                                                rowData={item.rows}
                                                columnDefs={item.columns}
                                                defaultColDef={{
                                                    flex: 1,
                                                    minWidth: 100,
                                                    resizable: true,
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </ReactGridLayout>
                </div>
            </Grid>
            <AddWidgetModal
                openModal={openAddWidgetModal}
                handleCloseModal={handleCloseAddWidgetModal}
                addWidget={addWidget}
            />
            <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={handleCloseSnackBar}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default CreateCustomDashboard;
