import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../../slices/loginSlice";
import { AppDispatch, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { Alert } from "@mui/lab";
import { resetAssetType } from "../../slices/global";

const SignIn: React.FC = () => {
    const formData = new FormData();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);
    const { data, loading, error } = useSelector((state: RootState) => state.loginData);
    const [userMessage, setUserMessage] = useState<{ message: string; alertSeverity: any }>({
        message: "",
        alertSeverity: "",
    });

    useEffect(() => {
        if (data && data.access_token) {
            dispatch(resetAssetType());
            localStorage.setItem("token", data.access_token);
            navigate("/fleet/overview");
        }
    }, [data, navigate, dispatch]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formData.append("username", username);
        formData.append("password", password);
        dispatch(postData(formData));
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (error) {
            setUserMessage({ message: error, alertSeverity: "error" });
            setOpen(true);
        }
    }, [error]);

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-200">
            <div className="container flex max-w-6xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="w-1/2 p-10 bg-gray-50 hidden lg:flex flex-col justify-center">
                    <div>
                        <div className="flex items-start mb-6">
                            <span className="text-2xl mr-4">⚙️</span>
                            <div>
                                <h4 className="text-lg font-semibold">Adaptable performance</h4>
                                <p className="text-sm text-gray-600">
                                    Our product effortlessly adjusts to your needs, boosting efficiency and simplifying
                                    your tasks.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start mb-6">
                            <span className="text-2xl mr-4">🛠️</span>
                            <div>
                                <h4 className="text-lg font-semibold">Built to last</h4>
                                <p className="text-sm text-gray-600">
                                    Experience unmatched durability that goes above and beyond with lasting investment.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start mb-6">
                            <span className="text-2xl mr-4">👍</span>
                            <div>
                                <h4 className="text-lg font-semibold">Great user experience</h4>
                                <p className="text-sm text-gray-600">
                                    Integrate our product into your routine with an intuitive and easy-to-use interface.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start mb-6">
                            <span className="text-2xl mr-4">✨</span>
                            <div>
                                <h4 className="text-lg font-semibold">Innovative functionality</h4>
                                <p className="text-sm text-gray-600">
                                    Stay ahead with features that set new standards, addressing your evolving needs
                                    better than the rest.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-1/2 p-10 flex flex-col justify-center">
                    <div className="max-w-md w-full mx-auto">
                        <h2 className="text-3xl font-semibold text-center mb-6 uppercase">Sign In</h2>
                        <form onSubmit={handleSubmit} action="#">
                            <div className="mb-4">
                                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                    Username
                                </label>
                                <input
                                    type="text"
                                    id="username"
                                    defaultValue={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder="your@email.com"
                                    required
                                />
                            </div>
                            <div className="mb-4 relative">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    defaultValue={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder="Password"
                                    required
                                />
                            </div>
                            <LoadingButton
                                className="w-full py-2 px-4 bg-black text-white rounded-md hover:bg-gray-800"
                                loading={loading}
                                type="submit"
                                variant="contained"
                            >
                                Sign in
                            </LoadingButton>
                        </form>
                    </div>
                </div>
            </div>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SignIn;
